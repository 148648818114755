


































import Vue from 'vue'
import { mapGetters } from 'vuex'
import Organization from '../../../../store/models/Organization'
import SwitcherActive from '../../components/SwitcherActive.vue'
import SwitcherDisabled from '../../components/SwitcherDisabled.vue'


export default Vue.extend({
  data() {
    return {
      columns: [
        { label: 'Nom', field: 'name', searchable: true },
        { label: 'Représentant', field: 'representativeName' },
        { label: 'Siège', field: 'address' },
        { label: 'Adresse électronique', field: 'email' },
        { label: 'Téléphone', field: 'contact' },
        { label: 'Administrateur', field: 'admin.fullname' },
        { label: 'Actions', field: '_actions' }
      ]
    }
  },

  computed: {
    items() {
      return Organization.query()
        .where('type', 'sfd')
        .with('admin')
        .all()
    }
  },
  methods: {
    activate(this: any, id: string) {
      Organization.api()
        .unblockdispatching(id)
        .then(this.$notify.success('Le SFD a été activer avec succès'))
        .catch((error: any) => {
          // TODO: handle update errors
          console.log(error) // eslint-disable-line
          this.$notify.error("Une erreur s'est produite.\n Veuillez réesayez")
        })
    },
    desactivate(this: any, id: string) {
      Organization.api()
        .blockdispatching(id)
        .then(() => this.$notify.success('Le SFD a été désactiver avec succès'))
        .catch((error: any) => {
          // TODO: handle update errors
          console.log(error) // eslint-disable-line
          this.$notify.error("Une erreur s'est produite.\n Veuillez réesayez")
        })
    }
  },

  created() {
    Organization.api().fetch()
  },
  components: { SwitcherActive, SwitcherDisabled }

})
